<template>
   <div class="product__widget-item mb-10">
      <div class="accordion" :id="'productWidgetAccordion'+ index">
         <div class="accordion-item">
            <h2 class="accordion-header" :id="'heading'+ index">
               <button class="accordion-button product__widget-title" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+ index" aria-expanded="true" :aria-controls="'collapse'+ index">{{ filtro.nome }}</button>
            </h2>
            <div :id="'collapse'+ index" class="accordion-collapse collapse show" :aria-labelledby="'heading'+ index" data-bs-parent="#accordionExample">
               <div class="accordion-body">
                  <campo v-for="(campo, index) in filtro.campos" :key="index" :campo="campo" :filtro="filtro.nome" />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import campo from '@/components/home/FiltroCampo.vue'

export default {
   name: 'Filtro',
   props: ['filtro', 'index'],
   components: {
      campo
   }
}

</script>