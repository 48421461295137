<template>
   <div class="limitador"><a href="javascript:;" @click="pesquisar">
      <span class="me-1">({{ campo.qtd }})</span> {{ campo.nome }}</a>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'FiltroCampo',
   props: ['campo', 'filtro'],
   computed: {
		... mapState({
			pesquisa: state => state.pesquisa
		})
	},
   methods: {
      pesquisar : function () {
         this.$store.dispatch('pesquisar', {
            'categoria': this.pesquisa.categoria,
            'subcategoria': this.filtro == 'Subcategorias' ? 'Subcategorias' : this.pesquisa.subcategoria,
            'tipoPesquisa': this.filtro,
            'valor': this.campo.nome
         })
      }
   }
}

</script>